import React from "react"
import styled from "styled-components"
import leaderboard from "../images/leaderboard.png"
import Layout from "../components/layout"

const Wrapper = styled.div`
  min-height: calc(100vh - 120px);
  /* min-height: fill-available; */
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 90vw;
    margin: 0;
  }
`

const Leaderboard = ({}) => (
  <Layout>
    <Wrapper>
      <img src={leaderboard} />
    </Wrapper>
  </Layout>
)

export default Leaderboard
